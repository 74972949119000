@import "./prefs";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.App {
  background: #000000;
  min-height: 100vh;
  header {
    background: $primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    .logo {
      text-align: center;
      color: #ffffff;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

* {
  box-sizing: border-box;
}
