.rdw-editor-main {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 1rem;
  padding: 1rem;
  padding-bottom: 0.2rem;
  border-radius: 0.375rem;
  border: 1px solid;
  border-color: inherit;
  background: inherit;
  min-height: 150px;
}

.rdw-editor-toolbar {
  border: 0;
  padding: 0;
  margin: 0;
  padding-top: 5px;
}

.rdw-option-wrapper {
  min-height: 30px;
  min-width: 30px;
}

.public-DraftStyleDefault-block {
  margin: 0;
}
