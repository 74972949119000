@import "./prefs";

.card {
  background: #ffffff;
  border-radius: 7px;
  overflow: hidden;
  padding: 25px;
  width: 300px;
  height: 340px;
  position: relative;
  z-index: 0;
  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .top {
    display: flex;
    justify-content: space-between;
    .emoji {
      font-size: 26px;
    }
    .ice {
      z-index: 999;
      cursor: pointer;
      font-weight: bold;
      padding: 3px 20px;
      background: #dedede;
      border-radius: 100px;
      height: 30px;
      &.approved {
        background: rgb(134, 255, 134);
      }
      &.rejected {
        background: #ffa595;
      }
    }
  }
  .content {
    padding-right: 20px;
    padding-bottom: 30px;
    .stage {
      font-size: 13px;
      font-weight: bold;
      color: #21c372;
      text-transform: capitalize;
      margin-bottom: 4px;
    }
    .title {
      font-weight: bold;
      font-size: 20px;
      height: 70px;
      margin-bottom: 15px;
    }
    .description {
      font-size: 15px;
      color: #525252;
      line-height: 1.4;
      height: 63px;
      margin-bottom: 7px;
      overflow: hidden;
    }
    .cost-estimate {
      font-weight: bold;
      .estimate {
        display: inline;
      }
    }
  }
}
