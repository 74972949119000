.idea-single-body,
.public-DraftStyleDefault-block {
  margin-bottom: 14px;
  font-size: 20px;

  h1,
  h2,
  h3 {
    font-weight: bold;
  }

  h1 {
    margin-bottom: 20px;
    font-size: 30px;
  }

  h2 {
    margin-bottom: 18px;
    font-size: 26px;
  }

  h3 {
    margin-bottom: 16px;
    font-size: 23px;
  }

  p,
  li {
    margin-bottom: 14px;
    font-size: 20px;
  }

  ul,
  ol {
    margin-left: 20px;
  }

  a {
    color: rgb(51, 133, 255);
  }
}
