@import "./prefs";

.form {
  background: #ffffff;
  border-radius: 7px;
  .chakra-form-control {
    & > label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 14px;
      color: #555;
    }
  }
}
.preview {
  position: absolute;
  left: 115%;
  top: 0;
  h2 {
    font-weight: bold;
    color: #999999;
    font-size: 18px;
  }
  .card-wrapper {
    border-radius: 7px;
    width: 300px;
    @include box-shadow(0px 8px 30px rgba(0, 0, 0, 0.2));
  }
}
